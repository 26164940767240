<template>
  <div class="page-container" v-loading="isLoading">
    <div class="breadcrumb-container">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                                       <el-breadcrumb-item><a href="/">文章列表</a></el-breadcrumb-item>
                                       <el-breadcrumb-item>文章详情</el-breadcrumb-item> -->
        <el-breadcrumb-item v-for="(v, i) in activeMenu" :key="i">{{
          v.name
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="content-header pb10" flex="main:justify cross:center">
        <div></div>
        <div class="search-wrap">
          <el-form
            :inline="true"
            :model="search"
            class="demo-form-inline"
            ref="searchForm"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="账号名称" prop="name">
              <el-input
                v-model="search.name"
                placeholder="请输入账号名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属游戏" prop="game_name">
              <el-input
                v-model="search.game_name"
                placeholder="请输入游戏名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="resetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="content-body">
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            :max-height="tableMaxHeight"
          >
            <el-table-column
              label="序号"
              type="index"
              :index="mGenTableIndex"
              width="50"
            >
            </el-table-column>
            <el-table-column prop="name" label="账号名称"></el-table-column>
            <el-table-column label="账号密码">
              <template slot-scope="scope">
                <el-input
                  style="width: 120px"
                  :value="scope.row.password"
                  size="mini"
                  show-password
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="账号价格">
              <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
            </el-table-column>
            <el-table-column
              prop="game_name"
              label="所属游戏"
            ></el-table-column>
            <el-table-column
              label="操作"
              width="250"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <div>
                  <el-link
                    class="app-mr10"
                    type="primary"
                    @click="addPosition(scope.row)"
                    >添加位置</el-link
                  >
                  <el-link
                    class="app-mr10"
                    type="primary"
                    @click="listPosition(scope.row)"
                    >位置列表</el-link
                  >
                  <el-link
                    class="app-mr10"
                    type="primary"
                    @click="edit(scope.row)"
                    >编辑</el-link
                  >
                  <el-link type="danger" @click="del(scope)">删除</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination app-pr10 app-pt10 app-pb10" flex="main:right">
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            @current-change="handleChangePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 新增游戏 -->
    <el-drawer
      title="新增"
      :visible.sync="showAdd"
      @close="resetForm('add')"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <el-form
            :model="formDataAdd"
            :rules="rules"
            ref="add"
            @keyup.enter.native="submitForm('add')"
          >
            <el-form-item label="游戏名称" prop="name">
              <el-input
                v-model="formDataAdd.name"
                placeholder="请输入游戏名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="submitForm('add')">提交</el-button>
          <el-button @click="showAdd = false">取消</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 编辑账号 -->
    <el-drawer
      title="编辑"
      :visible.sync="showEdit"
      @close="resetForm('edit')"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <el-form
            :model="formDataEdit"
            :rules="rules"
            ref="edit"
            @keyup.enter.native="submitForm('edit')"
          >
            <el-form-item label="游戏名称" prop="game_name">
              <el-input
                disabled
                v-model="formDataEdit.game_name"
                placeholder="请输入游戏名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="账号名称" prop="name">
              <el-input
                v-model="formDataEdit.name"
                placeholder="请输入账号名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="账号密码" prop="password">
              <div flex class="app-w100">
                <div flex-box="10">
                  <el-input
                    v-model="formDataEdit.password"
                    placeholder="请输入账号密码"
                    clearable
                  ></el-input>
                </div>
                <div flex-box="0">
                  <el-button
                    size="small"
                    style="margin-left: 5px"
                    @click="genRandomPass"
                    >随机密码</el-button
                  >
                </div>
              </div>
            </el-form-item>
            <el-form-item label="账号价格" prop="price">
              <el-input
                v-model="formDataEdit.price"
                placeholder="请输入账号价格"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="submitForm('edit')">提交</el-button>
          <el-button @click="showEdit = false">取消</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 新增位置 -->
    <el-drawer
      title="添加位置"
      :visible.sync="showAddPosition"
      @close="resetForm('addPosition')"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <el-form
            :model="formDataAddPosition"
            :rules="rulesPosition"
            ref="addPosition"
            @keyup.enter.native="handleAddPosition"
          >
            <el-form-item label="游戏名称" prop="game_name">
              <el-input
                disabled
                v-model="formDataAddPosition.game_name"
                placeholder="请输入游戏名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="账号名称" prop="account_name">
              <el-input
                disabled
                v-model="formDataAddPosition.account_name"
                placeholder="请输入账号名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="位置名称" prop="name">
              <el-input
                v-model="formDataAddPosition.name"
                placeholder="请输入位置名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="handleAddPosition">提交</el-button>
          <el-button @click="showAddPosition = false">取消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
// import {mapMutations, mapState, mapGetters, mapActions} from 'vuex';
import { mapActions, mapMutations, mapState } from "vuex";
import PanelDrawer from "../components/PanelDrawer.vue";

export default {
  name: "account-list",
  components: {
    PanelDrawer,
  },
  data() {
    return {
      formDataAddPosition: {
        game_id: 0,
        game_name: "",
        account_id: 0,
        account_name: "",
        name: "",
      },
      formDataAdd: {
        name: "", // 游戏名称
      },
      formDataEdit: {
        id: 0,
        game_id: 0,
        game_name: "",
        name: "",
        password: "",
        price: "",
      },
      rules: {
        name: [{ required: true, message: "请输入账号名称", trigger: "blur" }],
        password: [
          { required: true, message: "请输入账号密码", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入账号价格", trigger: "blur" },
          {
            required: true,
            type: "number",
            transform(v) {
              return +v;
            },
            message: "请输入正确的账号价格",
            trigger: "blur",
          },
        ],
      },
      rulesPosition: {
        name: [{ required: true, message: "请输入位置名称", trigger: "blur" }],
      },
      showDialogCloseTip: false,
      tableMaxHeight: 0,
      pagination: {
        page: 1,
        total: 0,
        pageSize: 15,
      },
      search: {
        game_id: this.$route.query.game_id ? this.$route.query.game_id : 0,
        name: "", // 账号名称
        game_name: "", // 游戏名称
      },
      record: {
        name: "",
        thumb: "",
        password: "",
        repassword: "",
        gender: 1,
        hobby: [],
        info: "",
        content: "",
        title: "", // 职级
        depart_belong: [],
        join_time: "",
        addr: [],
      },

      opt_depart_belong: [
        {
          label: "国防部",
          value: "gfb",
          children: [
            {
              label: "核心小组",
              value: "hxxz",
            },
            {
              label: "酱油组",
              value: "jyz",
            },
          ],
        },
        {
          label: "农业部",
          value: "nyb",
          children: [
            {
              label: "养猪办",
              value: "yzb",
            },
            {
              label: "种地办",
              value: "zdb",
            },
          ],
        },
      ],
      opt_addr: regionData,

      showDialogAdd: false,
      showDialogEdit: false,
      showDialogView: false,
      tableData: [],

      showAdd: false,
      showEdit: false,
      showAddPosition: false,
    };
  },
  computed: {
    ...mapState(["isLoading", "uploadBaseUrl", "activeMenu"]),
  },
  created() {
    this.getData();
  },
  mounted() {
    // this.record = {
    //     name: "丁禹",
    //     // thumb: "https://dss0.bdstatic.com/6Ox1bjeh1BF3odCf/it/u=4191265673,2281481057&fm=218&app=92&f=JPEG?w=121&h=75&s=C0B335D41CBB42940C08611A0300A060",
    //     thumb: "",
    //     password: "111111",
    //     repassword: "111111",
    //     gender: 1,
    //     hobby: ["西施", "貂蝉"],
    //     info: "简介",
    //     content: "<p>content here!</p>\n",
    //     title: "BOSS",
    //     depart_belong: ["gfb", "hxxz"],
    //     join_time: "2021-05-28",
    //     addr: ["510000", "510100", "510107"],
    // };
    // window.CKEDITOR.replace("content", {
    //   filebrowserImageUploadUrl: "http://test.my/upload.php",
    //   height: 500,
    // });
  },
  methods: {
    ...mapMutations(["disableLoading"]),
    listPosition(record) {
      this.$router.push({
        path: "/position-list-by-id",
        query: {
          account_id: record.id,
        },
      });
    },
    genRandomPass() {
      let password = this.$utils.genRandomPass({
        exclude: "iIl10oO",
        useSpecial: false,
      });
      if (password) this.formDataEdit.password = password;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if ("add" == formName) {
            // 新增
            this.$request.post("account/add", this.formDataAdd).then((res) => {
              if (res.code != 0) {
                this.mToast(res.message, "error");
                return;
              }

              this.mToast("操作成功", "success");
              this.showAdd = false;
              this.getData();
            });
          } else {
            // 编辑
            this.$request
              .post("account/update", this.formDataEdit)
              .then((res) => {
                if (res.code != 0) {
                  this.mToast(res.message, "error");
                  return;
                }

                this.mToast("操作成功", "success");
                this.showEdit = false;
                this.getData();
              });
          }
        }
      });
    },
    handleAddPosition() {
      this.$refs["addPosition"].validate((valid) => {
        if (valid) {
          // 新增
          this.$request
            .post("position/add", this.formDataAddPosition)
            .then((res) => {
              if (res.code != 0) {
                this.mToast(res.message, "error");
                return;
              }

              this.mToast("操作成功", "success");
              this.showAddPosition = false;
              this.getData();
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.getData();
    },
    handleSearch() {
      this.getData();
    },
    onDialogClosed() {
      if (this.showDialogCloseTip) {
        this.mToast("操作成功", "success");
        this.disableLoading();
        this.getData();
        this.showDialogCloseTip = false;
      }
    },
    initRecord() {
      this.record = {
        name: "",
        thumb: "",
        password: "",
        repassword: "",
        gender: 1,
        hobby: [],
        info: "",
        content: "",
        title: "", // 职级
        depart_belong: [],
        join_time: "",
        addr: [],
      };
    },
    formatRecord(record) {
      this.record = Object.assign({}, this.record, record);
      this.record.addr = this.record.addr.split(",");
      this.record.depart_belong = this.record.depart_belong.split(",");
      this.record.hobby = this.record.hobby.split(",");
    },
    handleChangePage(page) {
      console.log("page", page);
      this.pagination.page = page;
      this.getData();
    },
    getData() {
      let params = { page: this.pagination.page, ...this.search };
      // this.disableLoading();
      this.$request.get("account/index", { params: params }).then(
        (res) => {
          this.tableMaxHeight = this.calcTableHeight();
          this.tableData = res.data.items;
          this.pagination.total = res.data.pagination.totalCount;
          //   this.pagination.pageSize = res._meta.perPage;
        },
        (err) => console.log(err)
      );
    },
    calcTableHeight() {
      return (
        document.getElementsByClassName("page-container")[0].clientHeight -
        document.getElementsByClassName("breadcrumb-container")[0]
          .clientHeight -
        document.getElementsByClassName("content-header")[0].clientHeight -
        document.getElementsByClassName("pagination")[0].clientHeight
      );
    },
    handleAvatarFail(err) {
      if (typeof err.message === "string") {
        err.message = JSON.parse(err.message);
      }
      this.mToast(err.message.message, "error");
    },
    handleAvatarSuccess(res) {
      this.record.thumb = res;
    },
    beforeAvatarUpload(file) {
      // 可限制图片类型及大小
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      // this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
    },
    initRichText(id) {
      if (window.CKEDITOR.instances[id]) {
        window.CKEDITOR.instances[id].destroy();
      }
      this.$nextTick(function () {
        window.CKEDITOR.replace(id, {
          filebrowserImageUploadUrl:
            this.uploadBaseUrl + "/upload/ck-upload-img",
          height: 500,
        });
        // 滚动至dialog顶部
        document.getElementsByClassName("el-dialog__wrapper")[0].scrollTop = 0; // add
        document.getElementsByClassName("el-dialog__wrapper")[1].scrollTop = 0; // edit
      });
    },
    getRichTextValue(id) {
      if (window.CKEDITOR.instances[id]) {
        return window.CKEDITOR.instances[id].getData();
      }

      return null;
    },
    submitAdd() {
      // 验证
      let content = this.getRichTextValue("content_add");
      if (!content) {
        this.mToast("内容不能为空哦", "error");
        return false;
      } else {
        this.record.content = content;
      }

      this.$utils.logJson(this.record);
      this.disableLoading();
      this.$request.post("demo", this.record).then(
        () => {
          this.$refs["form-add"].resetFields();
          this.showDialogAdd = false;
          this.showDialogCloseTip = true;
        },
        (err) => {
          this.mToast(err.data[0]["message"], "error");
        }
      );
    },
    submitEdit() {
      // 验证
      let content = this.getRichTextValue("content_edit");
      if (!content) {
        this.mToast("内容不能为空哦", "error");
        return false;
      } else {
        this.record.content = content;
      }

      this.disableLoading();
      this.$request.put("demo/" + this.record.id, this.record).then(
        () => {
          this.$refs["form-edit"].resetFields();
          this.showDialogEdit = false;
          this.showDialogCloseTip = true;
        },
        (err) => {
          this.mToast(err.data[0]["message"], "error");
        }
      );
    },
    add() {
      this.showAdd = true;
    },
    addPosition(record) {
      this.formDataAddPosition.game_id = record.game_id;
      this.formDataAddPosition.game_name = record.game_name;
      this.formDataAddPosition.account_id = record.id;
      this.formDataAddPosition.account_name = record.name;
      this.showAddPosition = true;
    },
    view(record) {
      this.formatRecord(record);
      this.showDialogView = true;
      this.initRichText("content_view");
    },
    edit(record) {
      this.formDataEdit.id = record.id;
      this.formDataEdit.game_id = record.game_id;
      this.formDataEdit.game_name = record.game_name;
      this.formDataEdit.name = record.name;
      this.formDataEdit.password = record.password;
      this.formDataEdit.price = record.price;
      this.showEdit = true;
    },
    del(scope) {
      this.mConfirm("确定删除?", () => {
        // 进行登录
        this.$request
          .post("account/delete", { id: scope.row.id })
          .then((res) => {
            if (res.code != 0) {
              this.mToast(res.message, "error");
              return;
            }

            this.mToast("操作成功", "success");
            this.getData();
          });
      });
    },
  },
};
</script>

<style scoped lang="less">
.page-container {
  height: calc(100vh - @height-header);
  .drawer-wrapper {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .drawer-wrapper-content {
      flex: 1;
    }
    .drawer-wrapper-footer {
      text-align: right;
      padding-bottom: 10px;
    }
  }
  .form-mask {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 999;
  }
  .breadcrumb-container {
    padding: 20px 10px;
  }
  .content {
    padding: 0 10px 20px 10px;
  }
  .avatar-uploader {
    line-height: 0;
    .thumb-wrapper {
      img {
        left: 0;
        top: 0;
      }
      .thumb-mask {
        display: none;
      }
      &:hover {
        .thumb-mask {
          display: flex;
        }
      }
    }
    .avatar-uploader-icon {
      width: 75px;
      height: 75px;
      line-height: 75px;
      text-align: center;
      border: dashed 1px @border-color-1;
      color: #999;
      &:hover {
        border-color: @color-primary;
        color: @color-primary;
      }
    }
  }
}
</style>